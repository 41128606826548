@if (meetingTypes?.length > 1) {
  <div class="cw-meeting-providers">
    <h4 class="cw-meeting-provider-title">
      {{ (widgetTemplates | calioMeetingTemplate: { id: 307, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang }}
    </h4>
    <div class="custom-field form-group meeting-providers-padding">
      <label for="meeting_type_id" class="cw-meeting-input-label" id="label_meeting_type_id">
        <span [innerHTML]="((widgetTemplates | calioMeetingTemplate: { id: 203, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml"></span>
        <span>*</span>
      </label>

      <ng-select
        id="meeting_type_id"
        name="meeting_type_id"
        #meeting_type_id="ngModel"
        [(ngModel)]="appointmentState.meeting_type_id"
        [items]="meetingTypes"
        [clearable]="false"
        [bindValue]="'id'"
        [required]="true"
        [searchable]="false"
        (change)="onMeetingTypeChange($event)"
        class="cbw-meeting-type"
        labelForId="label_meeting_type_id"
        attr.aria-describedby="{{ (widgetTemplates | calioMeetingTemplate: { id: 203, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang }}"
        attr.aria-labelledby="label_meeting_type_id"
      >
        <ng-template ng-option-tmp let-meetingType="item">
          <div class="d-flex align-items-center">
            <div class="meeting-icon d-flex align-items-center">
              @if (meetingType.id === 1) {
                <img src="{{ environment.baseUrl }}/images/meetings/store.svg" alt="Store Logo" width="26px" />
              } @else if (meetingType.id === 2) {
                <img src="{{ environment.baseUrl }}/images/meetings/zoom.svg" alt="Zoom Logo" width="26px" />
              } @else if (meetingType.id === 3) {
                <img src="{{ environment.baseUrl }}/images/meetings/calenso.svg" alt="Calenso Logo" width="26px" />
              } @else if (meetingType.id === 4) {
                <img src="{{ environment.baseUrl }}/images/meetings/phone.svg" alt="Phone Logo" width="26px" height="30px" />
              } @else if (meetingType.id === 5) {
                <img src="{{ environment.baseUrl }}/images/meetings/customer.svg" alt="Customer Logo" width="26px" />
              } @else if (meetingType.id === 6) {
                <img src="{{ environment.baseUrl }}/images/meetings/unblu.svg" alt="Unblu Logo" width="26px" />
              } @else if (meetingType.id === 7) {
                <img src="{{ environment.baseUrl }}/images/meetings/teams.svg" alt="Teams Logo" width="26px" />
              } @else if (meetingType.id === 8) {
                <img src="{{ environment.baseUrl }}/images/meetings/meet.svg" alt="Meet Logo" width="26px" />
              } @else if (meetingType.id === 9) {
                <img src="{{ environment.baseUrl }}/images/meetings/gotomeeting.png" alt="Gotomeeting Logo" width="26px" />
              } @else if (meetingType.id === 10) {
                <img src="{{ environment.baseUrl }}/images/meetings/webex.png" alt="Webex Logo" width="26px" />
              } @else if (meetingType.id === 11) {
                <img src="{{ environment.baseUrl }}/images/meetings/phone.svg" alt="Phone Logo" width="26px" height="30px" />
              } @else if (meetingType.id === 13) {
                <img src="{{ environment.baseUrl }}/images/meetings/metaverse.svg" alt="Metaverse" width="26px" />
              }
            </div>

            @if ((widgetTemplates | calioMeetingTemplate: { id: meetingType.id, type: 'meeting_template' }); as widgetTemplate) {
              <div class="meeting-option">
                @if ((widgetTemplate.titleTemplate | translation: templateContent.TEMPLATE_CONTENT: lang)) {
                  <div
                    class="meeting-type-name"
                    [innerHTML]="(widgetTemplate.titleTemplate | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml">
                  </div>
                }
                @if ((widgetTemplate.descTemplate | translation: templateContent.TEMPLATE_CONTENT: lang)) {
                  <div
                    [innerHTML]="(widgetTemplate.descTemplate | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml">
                  </div>
                }
                @if (meetingType.id === 1 && selectedStore) {
                  <div class="cbw-summary-worker-store-location">
                    <span class="cbw-summary-worker-store-location-name">{{ selectedStore | translation: 'name' : lang }}</span>
                    @if (selectedStore.is_online === 0) {
                      <span class="cbw-summary-worker-store-location-separator">,&nbsp;</span>
                      <span class="cbw-summary-worker-store-location-address">
                        @if (selectedStore.street) {
                          <span>{{ selectedStore.street }}</span>
                        }
                        @if (selectedStore.zip) {
                          <span>,&nbsp;{{ selectedStore.zip }}</span>
                        }
                        @if (selectedStore.city) {
                          <span>&nbsp;{{ selectedStore.city }}</span>
                        }
                        @if (selectedStore.country) {
                          <span>,&nbsp;{{ selectedStore.country | translation: 'name' : lang }}</span>
                        }
                      </span>
                    }
                  </div>
                }
              </div>
            }
          </div>
        </ng-template>

        <ng-template ng-label-tmp let-meetingType="item">
          <div class="d-flex align-items-center">
            <div class="meeting-icon d-flex align-items-center">
              @if (meetingType.id === 1) {
                <img src="{{ environment.baseUrl }}/images/meetings/store.svg" alt="Store Logo" width="26px" />
              } @else if (meetingType.id === 2) {
                <img src="{{ environment.baseUrl }}/images/meetings/zoom.svg" alt="Zoom Logo" width="26px" />
              } @else if (meetingType.id === 3) {
                <img src="{{ environment.baseUrl }}/images/meetings/calenso.svg" alt="Calenso Logo" width="26px" />
              } @else if (meetingType.id === 4) {
                <img src="{{ environment.baseUrl }}/images/meetings/phone.svg" alt="Phone Logo" width="26px" height="30px" />
              } @else if (meetingType.id === 5) {
                <img src="{{ environment.baseUrl }}/images/meetings/customer.svg" alt="Customer Logo" width="26px" />
              } @else if (meetingType.id === 6) {
                <img src="{{ environment.baseUrl }}/images/meetings/unblu.svg" alt="Unblu Logo" width="26px" />
              } @else if (meetingType.id === 7) {
                <img src="{{ environment.baseUrl }}/images/meetings/teams.svg" alt="Teams Logo" width="26px" />
              } @else if (meetingType.id === 8) {
                <img src="{{ environment.baseUrl }}/images/meetings/meet.svg" alt="Meet Logo" width="26px" />
              } @else if (meetingType.id === 9) {
                <img src="{{ environment.baseUrl }}/images/meetings/gotomeeting.png" alt="Gotomeeting Logo" width="26px" />
              } @else if (meetingType.id === 10) {
                <img src="{{ environment.baseUrl }}/images/meetings/webex.png" alt="Webex Logo" width="26px" />
              } @else if (meetingType.id === 11) {
                <img src="{{ environment.baseUrl }}/images/meetings/phone.svg" alt="Phone Logo" width="26px" height="30px" />
              } @else if (meetingType.id === 13) {
                <img src="{{ environment.baseUrl }}/images/meetings/metaverse.svg" alt="Metaverse" width="26px" />
              }
            </div>

            @if ((widgetTemplates | calioMeetingTemplate: { id: meetingType.id, type: 'meeting_template' }); as widgetTemplate) {
              <div class="meeting-option">
                @if ((widgetTemplate.titleTemplate | translation: templateContent.TEMPLATE_CONTENT: lang)) {
                  <div class="meeting-type-name"
                    [innerHTML]="(widgetTemplate.titleTemplate | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml">
                  </div>
                }
                @if ((widgetTemplate.descTemplate | translation: templateContent.TEMPLATE_CONTENT: lang)) {
                  <div [innerHTML]="(widgetTemplate.descTemplate | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml">
                  </div>
                }
              </div>
            }
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
}

<div class="wizard-footer clearfix">
  <div class="row margin-x-0">
    <div class="col-4 d-flex align-items-center justify-content-start">
      @if (subscriptionTypeId < widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id) {
        <div class="d-md-none">
          <img
            alt="Calenso Logo"
            src="{{ environment.deployUrl }}/assets/images/{{ environment.footerLogoUrl }}"
            width="100"
          >
        </div>
      }
    </div>
    <div class="col-4 d-flex align-items-center justify-content-center">
      @if (subscriptionTypeId < widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id) {
        <div class="d-none d-md-block">
          <img
            alt="Calenso Logo"
            src="{{ environment.deployUrl }}/assets/images/{{ environment.footerLogoUrl }}"
            width="100"
          >
        </div>
      }
    </div>
    <div class="col-4 d-flex align-items-center justify-content-end next-btn-custom-right-padding">
      <calenso-button
        id="cbw-prev-button"
        class="me-2"
        text="{{ previousButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}"
        placement="left"
        [widgetColorConf]="widgetColorConf"
        (clickEvent)="navigateTo(appointmentConstant.WORKER)"
      />
      <calenso-button
        id="cbw-next-button"
        text="{{ nextButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}"
        icon="chevron-right"
        placement="right"
        [disabled]="appointmentState?.has_meeting_types_restrictions && !appointmentState?.meeting_type_id"
        [widgetColorConf]="widgetColorConf"
        (clickEvent)="navigateTo(appointmentConstant.DATE)"
      />
    </div>
  </div>
</div>