import { StoresDao } from './stores-dao';

export class UserDao {
  id: number;
  prename: string;
  lastname: string;
  avatar: string;
  partner_id: number;
  username: string;
  email: string;
  job_title: string;
  bookable: number;
  is_directly_bookable: boolean;
  store_id: number;
  photo_dir: string;
  registration_date: Date;
  updated: Date;
  first_login: number;
  street: string;
  zip: string;
  city: string;
  mobile: string;
  timezone: string;
  show_in_pro_calendar: number;
  status: number;
  uuid: string;
  full_name: string;
  store: StoresDao;
  selectedLanguage: string;
  browserLanguage: string;
  resource_name: string;
  booking_label: string;
  is_resource: number;
  booking_type_id: number;
  description: string;
  is_lead_generator: number;
  is_reseller_admin: number;
  location_dependency: boolean;
  is_multi_language: number;
  is_round_robin_enabled?: number;
  _translations: {
    [key: string]: UserTranslation;
  };
  attributes: AttributesModel[];
}

export interface AttributesModel {
  uuid: string;
  name: string;
  description: string;
  type: string;
  is_multi_language: number;
  _translations:  { [key: string]: BookingTypeAttributeValueTranslationDbModel }[];
  attributes_values: AttributesValuesModel[];
}

export interface WorkersAttributes {
  amount: number;
  is_visible_in_widget: boolean;
}

export interface BookingttributeTranslationModel {
  description: string
  name: string
  locale: string
}

export interface AttributesValuesModel {
  uuid: string;
  is_multi_language: boolean;
  value: string;
  _translations: { [key: string]: BookingTypeAttributeValueTranslationDbModel };
  icon_url: string;
}

interface BookingTypeAttributeValueTranslationDbModel {
  locale: string;
  value: string;
  icon_url: string;
}

export class UserTranslation {
  job_title: string;
  booking_label: string;
  description: string;
  locale: string;
  full_name: string;
  resource_name: string;
}
