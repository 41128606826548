import { NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { BOOKING_CUSTOM_FIELD_TYPES } from '../../../constants/widget-constants';
import { CustomFieldOptions, CustomFieldsDao } from '../../../db-models/appointment-custom-fields-dao';
import { MappedAppointmentServicesDao } from '../../../db-models/appointment-service-dao';
import { AppointmentState } from '../../../models/state.model';
import { AppointmentService } from '../../../services/appointment.service';
import { CustomEventService } from '../../../services/custom-event.service';
import { FormsService } from '../../../services/forms.service';
import { LoggerService } from '../../../services/logger.service';
import { PartnerService } from '../../../services/partner.service';
import { UtilService } from '../../../services/util.service';
import { CustomFieldComponent } from '../custom-field/custom-field.component';

@Component({
  selector: 'app-custom-field-view',
  templateUrl: './custom-field-view.component.html',
  styleUrls: ['./custom-field-view.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: true,
  imports: [CustomFieldComponent, NgTemplateOutlet]
})
export class CustomFieldViewComponent implements OnInit {

  @Input() customFields: CustomFieldsDao[];
  @Input() field: CustomFieldsDao;
  @Input() lang: string;
  @Input() selectedJsonCustomFields: {
    [key: number]: { type: string; value: any } | any;
  };
  @Input() customFieldValues: {
    [key: number]: {
      type: string,
      value: any,
      option_values: {
        custom_field_option_id: number,
        custom_field_option_value: string,
      }[]
    } | any
  };
  @Input() customFieldValuesLSNameKey: string;
  @Input() appointmentState?: AppointmentState;
  @Input() isFirst: boolean;
  @Input() isLast: boolean;
  @Input() widgetType: string;

  protected showDependenciesGhostElement = false;
  protected selectedChildIds: number[] = [];
  protected dependenciesGhostElements = [1, 2, 3];
  protected readonly bookingCustomFieldTypes = BOOKING_CUSTOM_FIELD_TYPES;

  constructor(
    private formsService: FormsService,
    private customEventService: CustomEventService,
    private utilService: UtilService,
    public partnerService: PartnerService,
    private appointmentService: AppointmentService
  ) {
  }

  ngOnInit(): void {
    this.initCheckEndOfProcess();
  }

  onSelectedChildIdsChange(ids: number[]): void {
    this.selectedChildIds = ids;
  }

  onCheckboxChangeEvent(event: {
    customField: CustomFieldsDao,
    checked: boolean,
  }): void {
    if (event.checked) {
      if (this.field?.child_custom_field?.id) {
        this.customFieldValues = this.removeCustomFieldValuesByIds([this.field.child_custom_field.id], this.customFieldValues);
        this.getCustomFieldDependencies(this.field, [this.field.child_custom_field.id]);
      }
    } else {
      delete this.customFieldValues[this.field.child_custom_field_id];
      this.utilService.updateLocalStorage(this.customFieldValuesLSNameKey, this.customFieldValues);
      this.initCheckEndOfProcess();
    }
  }

  onRadioButtonChangeEvent(event: {
    customField: CustomFieldsDao,
    customFieldOption: CustomFieldOptions,
    oldCustomFieldDependencyIds: number[],
    removeOldCustomFieldDependencyIds: boolean,
  }): void {
    if (event?.oldCustomFieldDependencyIds?.length && event.removeOldCustomFieldDependencyIds) {
      this.customFieldValues = this.removeCustomFieldValuesByIds(event?.oldCustomFieldDependencyIds, this.customFieldValues);
    }

    if (event?.customFieldOption?.dependencies && event?.customFieldOption?.dependencies?.trim() !== '') {
      const dependencies = event.customFieldOption.dependencies.split(',');
      const cfDependencies = dependencies.map(i => Number(i));
      this.getCustomFieldDependencies(event.customField, cfDependencies);
    } else {
      this.initCheckEndOfProcess();
    }

    if (event.customFieldOption.supported_appointment_service_ids) {
      const selectedOptionSupportedServiceIds = JSON.parse(event.customFieldOption.supported_appointment_service_ids) as string[];
      const mappedServiceIdsObj: MappedAppointmentServicesDao = {};
      mappedServiceIdsObj[event.customField.id] = {
        serviceIds: selectedOptionSupportedServiceIds.map(Number),
        is_multiple_select: !!Number(event.customField.is_multiple_select)
      };
      this.appointmentService.mappedServiceIds = mappedServiceIdsObj;
    }
  }

  getCustomFieldDependencies(customFieldData: CustomFieldsDao, cfDependencies: number[]): void {
    this.showDependenciesGhostElement = true;
    this.formsService.getCustomFieldsByIds(cfDependencies).subscribe({
      next: (result: CustomFieldsDao[]) => {
        this.showDependenciesGhostElement = false;
        if (result && result.length > 0) {
          this.customEventService.childCustomFieldsLoadedEvent.emit(result);
          this.updateCustomFieldDependencies(result);
          customFieldData.customFieldDependencies = result;
        }

        this.initCheckEndOfProcess();
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
        this.showDependenciesGhostElement = false;
      }
    });
  }

  initCheckEndOfProcess(): void {
    let tempList: CustomFieldsDao[] = [];
    if (this.partnerService.customFieldsBefore?.length > 0) {
      tempList = tempList.concat(this.partnerService.customFieldsBefore);
    }
    if (this.partnerService.customFieldsAfter?.length > 0) {
      tempList = tempList.concat(this.partnerService.customFieldsAfter);
    }
    this.checkEndOfProcessInCustomFields(tempList);
  }

  checkEndOfProcessInCustomFields(customFields: CustomFieldsDao[]): void {
    // this.customEventService.checkEndOfProcessEvent.emit();
    if (this.recursiveCheckEndOfProcess(customFields)) {
      this.customEventService.hideBookingButtonEvent.emit({
        hide: true
      });
    } else {
      this.customEventService.hideBookingButtonEvent.emit({
        hide: false
      });
    }
  }

  recursiveCheckEndOfProcess(customFields: CustomFieldsDao[]): boolean {
    for (const customField of customFields) {
      if (customField.is_end_of_process === 1) {
        LoggerService.warn('[End of process custom field]', customField);
        return true;
      }
      if (customField?.customFieldDependencies?.length > 0) {
        return this.recursiveCheckEndOfProcess(customField?.customFieldDependencies);
      }
    }
    return false;
  }

  removeCustomFieldValuesByIds(
    ids: number[],
    customFieldValues: {
      [key: number]: {
        type: string, value: any, option_values: {
          custom_field_option_id: number, custom_field_option_value: string,
        }[]
      } | any
    }
  ): {
    [key: number]: {
      type: string, value: any, option_values: {
        custom_field_option_id: number, custom_field_option_value: string,
      }[]
    } | any
  } {
    if (!customFieldValues) {
      return;
    }

    this.customEventService.removeChildCustomFieldsEvent.emit(ids);
    return customFieldValues;
  }

  updateCustomFieldDependencies(customFieldDependencies: CustomFieldsDao[]): void {
    // Add default option if it is select field
    for (const customFieldDependency of customFieldDependencies) {
      if ([this.bookingCustomFieldTypes.SELECT, this.bookingCustomFieldTypes.CARD_SELECT, this.bookingCustomFieldTypes.IMAGE_SELECT].includes(customFieldDependency.type)) {
        // Prevet duplication of default option
        if (customFieldDependency.custom_field_options.length
          && customFieldDependency.custom_field_options[0]['id'] != null
          && customFieldDependency.type !== this.bookingCustomFieldTypes.CARD_SELECT
          && customFieldDependency.type !== this.bookingCustomFieldTypes.IMAGE_SELECT
        ) {
          const tempCustomFieldOptions: CustomFieldOptions = new CustomFieldOptions();
          tempCustomFieldOptions.id = null;
          customFieldDependency.custom_field_options.unshift(tempCustomFieldOptions);
        }
        if (!this.customFieldValues[customFieldDependency.id]) {
          if (customFieldDependency.is_multiple_select === 1) {
            const temp: string =
              this.selectedJsonCustomFields && this.selectedJsonCustomFields[customFieldDependency.id] ? this.selectedJsonCustomFields[customFieldDependency.id] : undefined;
            let finalValue: any[] = temp ? temp.split(',') : [];
            if (finalValue?.length > 0) {
              finalValue = finalValue.map((item) => Number(item));
            }
            this.customFieldValues[customFieldDependency.id] = { value: finalValue, type: customFieldDependency.type };
          } else {
            if (customFieldDependency.type === this.bookingCustomFieldTypes.SELECT) {
              if (this.selectedJsonCustomFields && this.selectedJsonCustomFields[customFieldDependency.id]) {
                this.customFieldValues[customFieldDependency.id] = {
                  value: Number(this.selectedJsonCustomFields[customFieldDependency.id]),
                  type: customFieldDependency.type,
                };
              } else {
                this.customFieldValues[customFieldDependency.id] = {
                  value: '',
                  type: customFieldDependency.type,
                };
              }
            } else if (customFieldDependency.type === this.bookingCustomFieldTypes.CARD_SELECT || customFieldDependency.type === this.bookingCustomFieldTypes.IMAGE_SELECT) {
              if (this.selectedJsonCustomFields && this.selectedJsonCustomFields[customFieldDependency.id]) {
                this.customFieldValues[customFieldDependency.id] = {
                  value: [Number(this.selectedJsonCustomFields[customFieldDependency.id])],
                  type: customFieldDependency.type,
                };
              } else {
                this.customFieldValues[customFieldDependency.id] = {
                  value: [],
                  type: customFieldDependency.type,
                };
              }
            }
          }
        }
      } else if (customFieldDependency.type === this.bookingCustomFieldTypes.NUMBER_SELECT) {
        const optionIds: number[] = customFieldDependency.custom_field_options.map((item: CustomFieldOptions) => item.id);
        const optionValues: {
          custom_field_option_id: number,
          custom_field_option_value: any
        }[] = customFieldDependency.custom_field_options.map((item: CustomFieldOptions) => {
          item.option_value = item.number_default;
          return {
            custom_field_option_id: item.id,
            custom_field_option_value: item.number_default
          };
        });

        this.customFieldValues[customFieldDependency.id] = {
          value: optionIds,
          option_values: optionValues,
          type: customFieldDependency.type
        };
      } else if (customFieldDependency.type === this.bookingCustomFieldTypes.FILE) {
        this.customFieldValues[customFieldDependency.id] = {
          type: this.bookingCustomFieldTypes.FILE,
          file_ids: this.customFieldValues[customFieldDependency.id]?.file_ids?.length > 0 ?
            this.customFieldValues[customFieldDependency.id]?.file_ids : [],
        };
      } else if ([this.bookingCustomFieldTypes.TEXT, this.bookingCustomFieldTypes.TEXTAREA, this.bookingCustomFieldTypes.RADIO, this.bookingCustomFieldTypes.CHECKBOX, this.bookingCustomFieldTypes.DATE].includes(customFieldDependency.type)) {
        if (this.selectedJsonCustomFields && this.selectedJsonCustomFields[customFieldDependency.id]) {
          this.customFieldValues[customFieldDependency.id] = this.selectedJsonCustomFields[customFieldDependency.id];
        } else {
          this.customFieldValues[customFieldDependency.id] = this.customFieldValues[customFieldDependency.id] ? this.customFieldValues[customFieldDependency.id] : undefined;
        }
      }
    }
  }
}
