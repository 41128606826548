import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { APPOINTMENT_CONSTANT } from '../../../../constants/appointment-constants';
import { TRANSLATION_TEMPLATES } from '../../../../constants/translation-templates-constants';
import { WIDGET_CONSTANTS } from '../../../../constants/widget-constants';
import { CustomBookingMessageTemplate } from '../../../../db-models/widget-conf-dao';
import { Partner } from '../../../../models/global';
import { MeetingTypeModel } from '../../../../models/meeting-types.model';
import { AppointmentState, UserState } from '../../../../models/state.model';
import { WidgetColorConf } from '../../../../models/widget-color.model';
import { FormsService } from '../../../../services/forms.service';
import { LoggerService } from '../../../../services/logger.service';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { CalioMeetingTemplatePipe } from '../../../../shared/pipes/calio-meeting-template.pipe';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';
import { TrustHtmlPipe } from '../../../../shared/pipes/trust-html.pipe';

const pipes = [TranslationPipe, TrustHtmlPipe, CalioMeetingTemplatePipe];
const modules = [NgSelectModule, FormsModule, TranslateModule];
const components = [ButtonComponent];
@Component({
  selector: 'app-appointment-meeting-type',
  standalone: true,
  imports: [...pipes, ...modules, ...components],
  templateUrl: './appointment-meeting-type.component.html',
  styleUrl: './appointment-meeting-type.component.scss'
})
export class AppointmentMeetingTypeComponent implements OnInit {
  private formService = inject(FormsService);
  private translate = inject(TranslateService);

  @Input() lang: string;
  @Input() appointmentState: AppointmentState;
  @Input() userState: UserState;
  @Input() partner: Partner;
  @Input() widgetTemplates: CustomBookingMessageTemplate[] = [];
  @Input() widgetColorConf: WidgetColorConf;
  @Input() nextButtonTemplate: CustomBookingMessageTemplate;
  @Input() previousButtonTemplate: CustomBookingMessageTemplate;
  @Input() subscriptionTypeId: number;

  @Output() navigateToEvent = new EventEmitter<any>();
  @Output() hideMeetingTypesStepValue = new EventEmitter<boolean>();

  protected meetingTypes: MeetingTypeModel[];
  protected readonly environment = environment;
  protected readonly templateContent = TRANSLATION_TEMPLATES;
  protected readonly appointmentConstant = APPOINTMENT_CONSTANT;
  protected readonly widgetConstant = WIDGET_CONSTANTS;

  constructor() {
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
  }

  ngOnInit(): void {
    this.appointmentState?.has_meeting_types_restrictions && this.getAppointmentMeetingTypes();
  }

  onMeetingTypeChange(meetingType: MeetingTypeModel): void {
    this.appointmentState.meeting_type_id = meetingType.id;
    this.userState.meeting_type_id = meetingType.id;
    this.userState.meetingType = meetingType;
  }

  getAppointmentMeetingTypes(): void {
    const workerId = this.appointmentState.worker;
    const serviceIds = this.appointmentState.services;
    this.formService.getMeetingTypesByAppointmentServiceIds(workerId, serviceIds).subscribe({
      next: meetingTypes => {
        if (meetingTypes?.length) {
          meetingTypes.forEach(meetingType => meetingType.is_multi_language = 1);
        }
        this.meetingTypes = meetingTypes?.length ? meetingTypes : [];
        if (meetingTypes.length === 1) {
          this.hideMeetingTypesStepValue.emit(true);
          this.onMeetingTypeChange(meetingTypes[0]);
          this.navigateTo(this.appointmentConstant.DATE);
        }
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  navigateTo(page: string): void {
    this.navigateToEvent.next(page);
  }
}
