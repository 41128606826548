import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CalendarHighlightModel } from '../db-models/calendar-highlight.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CalendarHighlightService {

  private http = inject(HttpClient);

  getAllHighlights(to: string): Observable<CalendarHighlightModel[]> {
    const params = new HttpParams()
      .set('from', moment().format('YYYY-MM-DD'))
      .set('to', to)
      .set('status', true)
    return this.http.get<CalendarHighlightModel[]>(environment.apiUrl + `calendar_highlights`, { params: params });
  }
}
