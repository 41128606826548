import { NgClass, TitleCasePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TRANSLATION_TEMPLATES } from '../../../constants/translation-templates-constants';
import { LOCAL_STORAGE_CONSTANTS, PAYMENT_TYPES, WIDGET_CONSTANTS } from '../../../constants/widget-constants';
import { PayPalOrderDetailModel, PayPalSettingsDbModel } from '../../../db-models/partner-setting.model';
import { CustomBookingMessageTemplate } from '../../../db-models/widget-conf-dao';
import { CartItem, EventCartItem, GuestModel } from '../../../models/cart.model';
import { Partner } from '../../../models/global';
import { AssertPaymentPageSaferpayResponse, SaferpayPaymentDetailsDbModel } from '../../../models/saferpay.model';
import { CustomEventService } from '../../../services/custom-event.service';
import { FormsService } from '../../../services/forms.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { LoggerService } from '../../../services/logger.service';
import { WidgetUtilService } from '../../../services/widget-util.service';
import { AlertComponent } from '../../../shared/components/alert/alert.component';
import { TranslationPipe } from '../../../shared/pipes/translation.pipe';
import { CouponFormComponent } from '../coupon-form/coupon-form.component';
import { CwPaypalComponent } from '../cw-paypal/cw-paypal.component';
import { CwSaferpayComponent } from '../cw-saferpay/cw-saferpay.component';
import { StripeComponent } from '../stripe/stripe.component';
import { CwCardHeaderComponent } from '../theme/cw-card-header/cw-card-header.component';
import { CwNewCardComponent } from '../theme/cw-new-card/cw-new-card.component';

@Component({
  selector: 'app-cw-payment-methods',
  templateUrl: './cw-payment-methods.component.html',
  styleUrls: ['./cw-payment-methods.component.scss'],
  standalone: true,
  imports: [CwNewCardComponent, CwCardHeaderComponent, NgClass, AlertComponent, NgSelectModule, FormsModule, CouponFormComponent, StripeComponent, CwPaypalComponent, CwSaferpayComponent, TranslateModule, TranslationPipe],
  providers: [TitleCasePipe]
})
export class CwPaymentMethodsComponent implements OnInit, OnChanges {

  @Input() isStripeEnabled = false;
  @Input() isOnlinePaymentMandatory = false;
  @Input() isBexioEnabled = false;
  @Input() isStoreEnabled = false;
  @Input() isPaypalEnabled = false;
  @Input() isSaferpayEnabled = false;
  @Input() isLexOfficePaymentTypeEnabled = false;
  @Input() paypalSetting: PayPalSettingsDbModel;
  @Input() paymentType: string;
  @Input() totalAmount: number;
  @Input() priceAfterCouponReduction: number;
  @Input() courtesyForm: string;
  @Input() partner: Partner;
  @Input() lang: string;
  @Input() widgetTemplates: CustomBookingMessageTemplate[] = [];
  @Input() cart: CartItem[];
  @Input() eventCart: EventCartItem[];
  @Input() appoinrmentServicesAvailable: any;
  @Input() widgetType: string;
  @Input() additionalGuests: GuestModel[] = [];
  @Input() saferpaySucessfulPaymentEvent: Observable<AssertPaymentPageSaferpayResponse>;
  @Input() customCSS = "";

  @Output() couponSuccessEvent = new EventEmitter<string>();
  @Output() couponFailedEvent = new EventEmitter<{
    disableBookingButton: boolean,
    invalidCoupon: boolean,
  }>();
  @Output() changePaymentTypeEvent = new EventEmitter<string>();
  @Output() paypalSuccessfulEvent = new EventEmitter<PayPalOrderDetailModel>();
  @Output() resetPaypalEvent = new EventEmitter<any>();
  @Output() saferpaySuccessfulEvent = new EventEmitter<SaferpayPaymentDetailsDbModel>();
  @Output() resetSaferpayEvent = new EventEmitter<string | void>();
  @Output() stripeSuccessfulEvent = new EventEmitter<string>();
  @Output() resetStripeTokenEvent = new EventEmitter<any>();

  readonly widgetConstants = WIDGET_CONSTANTS;
  readonly templateContent = TRANSLATION_TEMPLATES;
  readonly paymentTypes = PAYMENT_TYPES;

  price: number;
  templateTitle: CustomBookingMessageTemplate;
  templateSubTitle: CustomBookingMessageTemplate;
  inStoreTemplate: CustomBookingMessageTemplate;
  paypalTemplate: CustomBookingMessageTemplate;
  saferpayTemplate: CustomBookingMessageTemplate;
  showCouponField = false;
  paymentIntentClientSecret: { secret: string };
  hidePaymentOptions = false;
  showPaymentSuccessMsg = false;
  paymentSuccessMsg: string;
  showPaymentFailedMsg = false;
  paymentFailedMsg: string;
  showPaymentTypeError = false;
  hasValidCoupons = 0;

  constructor(
    private translate: TranslateService,
    public formService: FormsService,
    private customEventService: CustomEventService,
    private titlecasePipe: TitleCasePipe,
    private localStorageService: LocalStorageService,
    private widgetUtilService: WidgetUtilService
  ) {
    this.customEventService.paymentGatewayErrorEvent.subscribe(result => this.showPaymentTypeError = result);
  }

  ngOnInit(): void {
    this.hasValidCoupons = this.widgetUtilService.getWidgetConf()?.has_valid_coupons;
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
    this.customCSS += (this.widgetType === this.widgetConstants.COMPACT ? ' compact-payment-method' : '');

    this.priceAfterCouponReduction = this.totalAmount;
    this.calculatePrice();

    this.customEventService.additionalGuestChangeEvent.subscribe(result => {
      if (this.widgetType === this.widgetConstants.EVENT) {
        this.price = result.finalPrice;
      }
    });

    this.handlePaymentTypePredefinedValue();
  }

  handlePaymentTypePredefinedValue(): void {
    this.localStorageService.getItem(LOCAL_STORAGE_CONSTANTS.LAST_PAYMENT_METHOD, (lastPaymentType: null | undefined | string) => {
      if (lastPaymentType !== null && lastPaymentType !== undefined && lastPaymentType !== '') {
        this.onChangePaymentType(lastPaymentType);
      } else if (this.isStoreEnabled) {
        this.onChangePaymentType(this.paymentTypes.STORE);
      }
    });
  }

  calculatePrice(): number {
    if (this.priceAfterCouponReduction || this.priceAfterCouponReduction === 0) {
      this.price = this.priceAfterCouponReduction;
    } else {
      this.price = this.totalAmount;
    }
    return this.price;
  }

  displayCouponField(): void {
    this.showCouponField = !this.showCouponField;
  }

  ngOnChanges(changes: SimpleChanges): void {
    let checkForPaymentChange = false;
    if (this.widgetType !== this.widgetConstants.EVENT) {
      this.calculatePrice();
    }

    if (changes?.widgetTemplates?.currentValue) {
      this.widgetTemplates?.length && this.setupTemplates();
    }

    if (changes && changes?.isStripeEnabled !== undefined) {
      this.isStripeEnabled = changes.isStripeEnabled.currentValue;
      checkForPaymentChange = true;
    }

    if (changes && changes?.isBexioEnabled !== undefined) {
      this.isBexioEnabled = changes.isBexioEnabled.currentValue;
      checkForPaymentChange = true;
    }

    if (changes && changes?.isSaferpayEnabled !== undefined) {
      this.isSaferpayEnabled = changes.isSaferpayEnabled.currentValue;
      checkForPaymentChange = true;
    }

    if (changes && changes?.isPaypalEnabled !== undefined) {
      this.isPaypalEnabled = changes.isPaypalEnabled.currentValue;
      checkForPaymentChange = true;
    }

    if (changes && changes?.isStoreEnabled !== undefined) {
      this.isStoreEnabled = changes.isStoreEnabled.currentValue;
      checkForPaymentChange = true;
    }

    if (changes && changes?.isLexOfficePaymentTypeEnabled !== undefined) {
      this.isLexOfficePaymentTypeEnabled = changes.isLexOfficePaymentTypeEnabled.currentValue;
      checkForPaymentChange = true;
    }

    if (checkForPaymentChange) {
      this.handlePaymentTypePredefinedValue();
    }
  }

  setupTemplates(): void {
    this.templateTitle = this.widgetTemplates.find(template => template.id === 122);
    this.templateTitle && (this.templateTitle.is_multi_language = 1);

    this.templateSubTitle = this.widgetTemplates.find(template => template.id === 123);
    this.templateSubTitle && (this.templateSubTitle.is_multi_language = 1);

    this.inStoreTemplate = this.widgetTemplates.find(template =>
      template.identifier === this.widgetConstants.WIDGET_LABELS.WIDGET_IN_STORE_LABEL
    );
    this.inStoreTemplate && (this.inStoreTemplate.is_multi_language = 1);

    this.paypalTemplate = this.widgetTemplates.find(template =>
      template.identifier === this.widgetConstants.WIDGET_LABELS.WIDGET_PAYPAL_LABEL
    );
    this.paypalTemplate && (this.paypalTemplate.is_multi_language = 1);

    this.saferpayTemplate = this.widgetTemplates.find(template =>
      template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_SAFERPAY_LABEL
    );
    this.saferpayTemplate && (this.saferpayTemplate.is_multi_language = 1);
  }

  onChangePaymentType(paymentType: string): void {
    this.showPaymentTypeError = false;
    this.changePaymentTypeEvent.emit(paymentType);
  }

  createStripePaymentIntent(): void {
    this.formService.createStripePaymentIntent(this.price).subscribe({
      next: (result: { secret: string }) => {
        this.paymentIntentClientSecret = result;
        this.customEventService.paymentIntentEvent.emit(this.paymentIntentClientSecret);
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }

  onPaypalSuccessfulEvent(event: PayPalOrderDetailModel): void {
    this.paypalSuccessfulEvent.emit(event);
    this.hidePaymentOptions = true;
    const paypalLabel = this.translate.instant('summary_page_translations.paypal');
    this.paymentSuccessMsg = this.translate.instant('summary_page_translations.payment_success_message', {
      paymentMethod: paypalLabel,
    });
    this.showPaymentSuccessMsg = true;
    this.showPaymentFailedMsg = false;
  }

  onSaferpaySuccessfulEvent(paymentDetails: SaferpayPaymentDetailsDbModel): void {
    this.saferpaySuccessfulEvent.emit(paymentDetails);
    this.hidePaymentOptions = true;
    const paymentMethod = this.titlecasePipe.transform(paymentDetails.payment_method);
    this.paymentSuccessMsg = this.translate.instant('summary_page_translations.payment_success_message', {
      paymentMethod: paymentMethod,
    });
    this.showPaymentSuccessMsg = true;
    this.showPaymentFailedMsg = false;
  }
}
