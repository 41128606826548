import { AsyncPipe, DatePipe, NgClass, NgStyle, SlicePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { Params } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalendarCommonModule, CalendarMonthModule, CalendarMonthViewDay } from 'angular-calendar';
import * as moment from 'moment';
import { of, Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { APPOINTMENT_CONSTANT } from '../../../constants/appointment-constants';
import { CalendarHighlightModel } from '../../../db-models/calendar-highlight.model';
import { UserDao } from '../../../db-models/user-data-dao';
import { Partner } from '../../../models/global';
import { AppointmentState, ConferenceState } from '../../../models/state.model';
import { WidgetColorConf } from '../../../models/widget-color.model';
import { CustomEventService } from '../../../services/custom-event.service';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { TranslationPipe } from '../../../shared/pipes/translation.pipe';
import { TrustHtmlPipe } from '../../../shared/pipes/trust-html.pipe';

@Component({
  selector: 'app-calendar-picker',
  templateUrl: './calendar-picker.component.html',
  styleUrls: ['./calendar-picker.component.scss'],
  standalone: true,
  imports: [LoaderComponent, NgClass, FontAwesomeModule, CalendarCommonModule, NgStyle, CalendarMonthModule, AsyncPipe, SlicePipe, DatePipe, TranslateModule, TranslationPipe, TrustHtmlPipe, NgbTooltip]
})
export class CalendarPickerComponent implements OnInit, AfterViewInit {

  @Input() noFreeDates: boolean;
  @Input() disablePrev: boolean;
  @Input() viewDate: Date;
  @Input() calendarLoaded = false;
  @Input() days: CalendarMonthViewDay[];
  @Input() widgetColorConf?: WidgetColorConf;
  @Input() set workerSetter(worker: UserDao) {
    this.worker = worker;
  };
  @Input() futureBookingThreshold: number;
  @Input() lang: string;
  @Input() calendarSlotsCountList?: {
    date: Date,
    slotsCount: number
  }[] = [];
  @Input() partner: Partner;
  @Input() calendarPreselectedDate: string;
  @Input() conferenceState: ConferenceState;
  @Input() calendarHighlightData: CalendarHighlightModel[];
  @Input() appointmentState: AppointmentState;

  @Output('viewDateChangedEvent') viewDateChangedEvent = new EventEmitter<Params>();
  @Output('dayClickedEvent') dayClickedEvent = new EventEmitter<any>();
  @Output('markExceptionDaysEvent') markExceptionDaysEvent = new EventEmitter<any>();

  private cdref = inject(ChangeDetectorRef);
  private customEventService = inject(CustomEventService);
  private translate = inject(TranslateService);

  protected readonly appointmentConstant = APPOINTMENT_CONSTANT;

  private preselectedEventTriggered = false;
  protected baseUrl = environment.baseUrl;
  protected disableNext = false;
  protected highlightedDatesDataSet: Record<string, { data: CalendarHighlightModel; css: string }>;
  protected refreshCalendar = new Subject<void>();
  protected worker: UserDao;
  protected workerAvatarUrl = environment.workerAvatarUrl;

  constructor() {
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
  }

  ngOnInit() {
    this.customEventService.disableCalendarNextButtonEvent.subscribe(disableNext => {
      setTimeout(() => this.disableNext = disableNext, 500);
    });


    this.customEventService.refreshCalendarEvent.subscribe(() => this.refreshCalendar.next());
    this.validateMaxDate(this.viewDate);
    this.highlightedDatesDataSet = this.generateHighlightData(this.calendarHighlightData);
  }

  protected viewDateChanged(viewDate: Date, isNext: boolean): void {
    this.validateMaxDate(viewDate);
    this.viewDate = viewDate;
    this.viewDateChangedEvent.emit({ viewDate: viewDate, isNext: isNext });
  }

  private validateMaxDate(viewDate: Date): void {
    if (this.futureBookingThreshold) {
      const maxDate = moment().add(this.futureBookingThreshold, 'days').toDate();
      this.disableNext = (viewDate.getMonth() == maxDate.getMonth() && viewDate.getFullYear() == maxDate.getFullYear())
    }
  }

  protected dayClicked(day: any): void {
    day?.day?.inMonth && (this.dayClickedEvent.emit(day));
  }

  protected markExceptionDays(body: any): void {
    this.markExceptionDaysEvent.emit(body);
  }

  protected getColorOfDay(day: any): any {
    let calendarPreselectedDate: Date;
    let hasAlreadySelected = false;

    if (this.calendarPreselectedDate) {
      calendarPreselectedDate = new Date(`${this.calendarPreselectedDate} 00:00:00`);
    }

    for (const calendarSlotsCount of this.calendarSlotsCountList) {
      if (day.date.toString() === calendarSlotsCount.date.toString()) {
        if (calendarSlotsCount.slotsCount && calendarSlotsCount.slotsCount > 0) {
          hasAlreadySelected = true;
          day.cssClass = 'cal-selected';
          day.badgeTotal = calendarSlotsCount.slotsCount;
        }
      }
    }

    if (this.calendarLoaded && hasAlreadySelected === false && calendarPreselectedDate) {
      if (day.date.getTime() === calendarPreselectedDate.getTime()) {
        if (
          day.isWeekend === false &&
          day.isPast === false &&
          !this.preselectedEventTriggered
        ) {
          this.preselectedEventTriggered = true;
          day.cssClass = 'cal-selected';
          this.dayClicked({ day });
        }
      }
    }

    if (day.cssClass === 'cal-selected') {
      return of({
        'color': 'white',
        'background-color': this.widgetColorConf.widget_header_active_color
      }).pipe(delay(500), switchMap((value: any) => of(value)));
    } else {
      if (day.isWeekend) {
        return of({ 'color': '' }).pipe(delay(500), switchMap((value: any) => of(value)));
      } else {
        return of({ 'color': this.widgetColorConf.widget_text_color }).pipe(delay(500), switchMap((value: any) => of(value)));
      }
    }
  }

  ngAfterViewInit(): void {
    this.cdref.detectChanges();
  }

  private generateHighlightData(data: CalendarHighlightModel[]): Record<string, { data: CalendarHighlightModel; css: string }> {
    const result: Record<string, { data: CalendarHighlightModel; css: string }> = {};

    // Filter and adjust objects
    const filteredData = data
      .filter((highlight) => {
        const endDate = moment(highlight.end, 'YYYY-MM-DD');
        return !endDate.isBefore(moment(), 'day'); // Keep only if the end date is today or in the future
      })
      .map((highlight) => {
        const startDate = moment(highlight.start, 'YYYY-MM-DD');
        const adjustedStartDate = startDate.isBefore(moment(), 'day') ? moment() : startDate; // Adjust start date if in the past
        return { ...highlight, start: adjustedStartDate.format('YYYY-MM-DD') };
      });

    filteredData.forEach(highlight => {
      if (highlight.appointment_service_id) {
        if (!this.appointmentState.services.includes(highlight.appointment_service_id)) {
          return;
        }
      } else if (highlight.worker_id) {
        if (this.appointmentState.worker !== highlight.worker_id) {
          return;
        }
      }

      const startDate = moment(highlight.start, 'YYYY-MM-DD');
      const endDate = moment(highlight.end, 'YYYY-MM-DD');
      let currentDate = startDate.clone();

      while (currentDate.isSameOrBefore(endDate)) {
        const dateKey = currentDate.format('YYYY-MM-DD');

        // Initialize the class string
        let cssClasses = 'cbw-highlight-day';

        // Add specific classes
        if (startDate.isSame(endDate)) {
          // If start and end are the same, add both start and end classes
          cssClasses += ' cbw-highlight-start cbw-highlight-end';
        } else if (currentDate.isSame(startDate)) {
          cssClasses += ' cbw-highlight-start';
        } else if (currentDate.isSame(endDate)) {
          cssClasses += ' cbw-highlight-end';
        } else {
          cssClasses += ' cbw-highlight-middle';
        }

        // Set the result for the current date
        result[dateKey] = {
          data: highlight,
          css: cssClasses,
        };

        currentDate.add(1, 'day');
      }
    });

    return result;
  }
}
